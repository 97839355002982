import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BooleanInput } from "react-admin";
import * as React from "react";
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  formGroup: () => ({
    padding: "15px 0",
    "& label": {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.sectionHeaderFontSize,
      fontWeight: theme.typography.fontWeightMedium,
      display: "flex",
      paddingBottom: 16,
    },
  }),
  description: () => ({
    padding: "16px",
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
  }),
  input: () => ({
    height: "2.5rem",
    width:'fit-content'
  }),
}));


const CustomBooleanInput = ({ source, modalTitle, modalText, lifter, inputLabel, sectionTitle, onValueChange, initialValue }) => {
  const styles = useStyles();
  const [inputValue, setInputValue] = useState(false);

  const handleLockCode =
    () => {
      lifter(!initialValue);
      setInputValue(!initialValue)
    }
  return (
    <>
      <div className={styles.formGroup}>
        <label>{sectionTitle}</label>
        <BooleanInput
          onClick={() => handleLockCode()}
          className={styles.input}
          onValueChange={() => onValueChange()}
          source={source}
          label={inputLabel}
          initialValue={initialValue}
        />
      </div>
    </>
  );
};

CustomBooleanInput.propTypes = {
  source: PropTypes.string,
  lifter: PropTypes.func,
};
export default CustomBooleanInput;
