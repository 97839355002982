import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>({
    timeInput: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    label: {
        position: 'absolute',
        top: 0,
        left: 0,
        fontSize: theme.typography.fontSize,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
        color: 'rgba(0, 0, 0, 0.54)',
        paddingLeft: '0.5rem',
        marginBottom: '0.5rem',
    },
    underline: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '2px',
        backgroundColor: 'rgba(0, 0, 0, 0.42)',
    },

    input: {
        appearance: 'none',
        background: 'transparent',
        outline: 'none',
        border: 'none',
        color: 'inherit',
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeight,
        height: '50px',
        backgroundColor: 'rgb(0,0,0,.05)',
        lineHeight: 1.5,
        padding: '.2rem 1rem 0 1rem',
        fontFamily: theme.typography.fontFamily,
        width: '100%',
        '&:hover': {
            backgroundColor: 'rgb(0,0,0,.1)',
            cursor: 'pointer'
        },
        '&::-webkit-calendar-picker-indicator':{
            height: '1.5rem',
            width: '1.5rem',
            '&:hover':{
                cursor: 'pointer'
            }
        }
    },

}));
const TimeInput = ({ label, value, onChange }) => {
    const classes = useStyles();
    return (
        <div className={classes.timeInput}>
            <label className={classes.label}>{label}</label>
            <input
                type="time"
                value={value}
                onChange={onChange}
                className={classes.input}
            />
            <div className={classes.underline}></div>
        </div>
    );
};

export default TimeInput;
