import * as React from 'react';
import {Menu, getResources, MenuItemLink, useTranslate} from 'react-admin';
import {useSelector} from "react-redux";
import DefaultIcon from "@material-ui/icons/ViewList";
import Settings from '@material-ui/icons/Settings';


/**
 * @description Custom Menu Component based on REACT.ADMIN.MENU
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomMenu = (props) => {
    const resources = useSelector(getResources);
    const translate = useTranslate()

    const translateString = (string) => { return translate(`resources.${string}.name`, { smart_count: 2 })}
    return (
        <Menu {...props}>
            {(resources.filter(r => r.hasList)).map(resource => (
                <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={translateString(resource.name)}
                    leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
                />
            ))}

            <MenuItemLink
                key={'school_settings'}
                to={`/${'school_settings'}`}
                primaryText={translateString('school_settings')}
                leftIcon={<Settings />}
            />
        </Menu>
    );
};
