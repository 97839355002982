import Cropper from "react-easy-crop";
import { useState, useCallback } from "react";
import { styled } from "@material-ui/core/styles";
import {ImageInput, SimpleForm } from "react-admin";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import getCroppedImg from "../../mixins/cropImage";
import { useTranslate } from "ra-core";

const WrapperForm = styled("div")({
  "& > div": {
    margin: 0,
  },
  "& .MuiFormHelperText-root": {
    display: "none",
  },
  "& .previews": {
    height: 300,
  },
  "& .previews > div": {
    height: "100%",
    width: "100%",
  },
});

const InputFilePlaceholderStyle = styled("div")(({ theme }) => ({
  padding: "30px 0",
  "& .input-file-placeholder-icon": {
    color: theme.palette.secondary.main,
  },
  "& .input-file-placeholder-icon .MuiSvgIcon-root": {
    fontSize: 40,
  },
  "& .input-file-placeholder-title": {
    fontSize: 16,
    fontWeight: 700,
    padding: "8px 0",
  },
  "& .input-file-placeholder-desc": {
    color: theme.palette.grey[600],
    fontSize: 16,
    fontWeight: 400,
    maxWidth: 400,
    margin: "0 auto",
  },
}));

const InputFilePlaceholder = ({ header, text }) => (
  <InputFilePlaceholderStyle>
    <div className="input-file-placeholder-icon">
      <OpenInBrowserIcon />
    </div>
    <div className="input-file-placeholder-title">{header}</div>
    <div className="input-file-placeholder-desc">{text}</div>
  </InputFilePlaceholderStyle>
);
const ImagePreview = ({
  record,
  source,
  rotation,
  setRotation,
  onCropComplete,
}) => {
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  return (
    <Cropper
      image={record[source]}
      crop={crop}
      rotation={rotation}
      zoom={zoom}
      aspect={4 / 3}
      onCropChange={setCrop}
      onRotationChange={setRotation}
      onCropComplete={onCropComplete}
      onZoomChange={setZoom}
    />
  );
};

const ImageCropper = ({ setImageCrop }) => {
  const translate = useTranslate();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [rotation, setRotation] = useState(0);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveCropImage = useCallback(
    async values => {
      try {
        const croppedImage = await getCroppedImg(
          values.image.src,
          croppedAreaPixels,
          rotation
        );
        setImageCrop({ ...values.image, src: croppedImage });
      } catch (e) {
        console.error(e)
      }
    },
    [croppedAreaPixels, rotation, setImageCrop]
  );
  return (
    <WrapperForm>
      <SimpleForm save={saveCropImage}>
        <ImageInput
          source="image"
          label=""
          accept="image/*"
          placeholder={
            <InputFilePlaceholder header={translate("resources.school_settings.avatar.upload_header")} text={translate("resources.school_settings.avatar.upload_text")} />
          }
        >
          <ImagePreview
            source="src"
            onCropComplete={onCropComplete}
            setRotation={setRotation}
            rotation={rotation}
          />
        </ImageInput>
      </SimpleForm>
    </WrapperForm>
  );
};

export default ImageCropper;
