import React, { useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import PropTypes, { string, bool, number } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {capitalizeWords} from "../../../mixins/utils";

const useStyles = makeStyles(theme => ({
  toggleAllInput: () => ({
    margin: "2.5rem 0.5rem",
  }),
  individualSwitch: () => ({
    marginBottom: ".2rem",
    minWidth: "25%",
    width: "25%",
  }),
  break: () => ({
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  }),
  column: () => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginLeft: ".5rem",
  }),
  noWrap: () => ({
    whiteSpace: "nowrap"
  })

}));

SwitchGroup.propTypes = {
  bulkLabel: string.isRequired,
  break: bool,
  initialSwitchState: PropTypes.arrayOf(
    PropTypes.shape({
      id: number,
      name: string,
      checked: bool,
      label: string,
      role_slug: string,
      last_name: string
    }).isRequired
  ),
  onValueChange: PropTypes.func.isRequired,
  disableAll: bool,
  className: string,
  showGroups: bool,
};

function SwitchGroup(props) {
  const styles = useStyles();
  const [toggleAll, setToggleAll] = useState(false);
  const [switchStates, setSwitchStates] = useState(props.initialSwitchState);

  useEffect(() => {
    props.onValueChange(switchStates);
  }, [switchStates]);
  const handleToggleAllChange = event => {
    const newSwitchStates = switchStates.map(switchState => ({
      ...switchState,
      checked: event.target.checked,
    }));
    setToggleAll(event.target.checked);
    setSwitchStates(newSwitchStates);
  };

  const handleSwitchChange = event => {
    const switchName = event.target.name;
    const newSwitchStates = switchStates.map(switchState =>
      switchState.name === switchName
        ? {
            ...switchState,
            checked: event.target.checked,
          }
        : switchState
    );
    setSwitchStates(newSwitchStates);
    setToggleAll(newSwitchStates.every(switchState => switchState.checked));
  };

  const splitAndSortUsersByRole = users => {
    const sortedUsers = users?.sort((a, b) => {
      return (a?.last_name || '').localeCompare(b?.last_name || '');
    });

    return sortedUsers?.reduce((result, user) => {
      const roleSlug = user.role_slug;
      const roleArray = result.find(arr => arr[0]?.role_slug === roleSlug);
      if (!roleArray) {
        result.push([user]);
      } else {
        roleArray.push(user);
      }
      return result;
    }, []);
  };

  return (
    <FormGroup className={props.className}>
      <FormControlLabel
        className={styles.toggleAllInput}
        control={
          <Switch
            color={"primary"}
            checked={toggleAll}
            onChange={handleToggleAllChange}
            disabled={props.disableAll}
          />
        }
        label={props.bulkLabel}
      />
      <div className={props.break ? styles.break : styles.column}>
        {props.showGroups
          ? splitAndSortUsersByRole(switchStates).map((group) => {
              return (
                <>
                  <Typography>{group[0]?.role_slug && capitalizeWords(group[0]?.role_slug)}</Typography>
                  {group.map(user => {
                    return (
                        <FormControlLabel
                          className={styles.individualSwitch}
                          key={user.name}
                          control={
                            <Switch
                              color={"primary"}
                              checked={user?.checked}
                              onChange={handleSwitchChange}
                              name={user?.name}
                              disabled={props.disableAll}
                            />
                          }
                          label={
                            <Typography
                              style={{ whiteSpace: "nowrap" }}
                              variant={"body2"}
                            >
                              {user?.label}
                            </Typography>
                          }
                        />
                    );
                  })}
                </>
              );
            })
          : switchStates?.map(switchState => (
              <FormControlLabel
                className={styles.individualSwitch}
                key={switchState.name}
                control={
                  <Switch
                    color={"primary"}
                    checked={switchState.checked}
                    onChange={handleSwitchChange}
                    name={switchState.name}
                    disabled={props.disableAll}
                  />
                }
                label={
                  <Typography
                    className={styles.noWrap}
                    variant={"body2"}
                  >
                    {switchState.label}
                  </Typography>
                }
              />
            ))}
      </div>
    </FormGroup>
  );
}

export default SwitchGroup;
