import React from 'react';
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {string} from "prop-types";


const useStyles = makeStyles((theme) => ({
    info: {
        border: `2px solid ${theme.palette.primary.main}`,
        position: 'relative',
        padding: '1rem',
    },
    icon:{
        position:"absolute",
        top: '-10px',
        left: '-10px',
        background: theme.palette.primary.contrastText,
        borderRadius: '50%',
    },
}));

InfoCard.propTypes = {
    text: string.isRequired,
    width: string // any css unit like "50%", "50px" etc.
};
function InfoCard({text,width='50%'}) {
    const classes = useStyles();

    return (
        <div className={classes.info} style={{width:width}}>
            <InfoOutlined className={classes.icon} color="primary"/>
            <Typography variant={"caption"} >
                {text}
            </Typography>
        </div>
    );
}

export default InfoCard;
