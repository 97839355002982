import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, styled } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import { Loading } from "react-admin";

const DialogComponent = styled(Dialog)({
  "& .MuiDialogContent-root": {
    margin: 0,
    padding: 0,
  },
});

const useStyles = makeStyles(theme => ({
  center: () => ({
    width: "fit-content",
    height: "fit-content",
    position: "absolute",
    zIndex: "99",
    top: "50%",
    left: "50%",
  }),
  visible: () => ({
    width: "100%",
  }),
  blurred: () => ({
    width: "100%",
    filter: "blur(10px)",
  }),
  icon: () => ({
    position: "absolute",
    right: 12,
    top: 8,
    color: theme => theme.palette.grey[500],
  })
}));

const Modal = ({
  title,
  isOpen,
  onClose,
  children,
  maxWidth = "sm",
  isLoading,
}) => {
  const styles = useStyles()
  return (
    <DialogComponent
      fullWidth
      open={isOpen}
      onClose={onClose}
      maxWidth={maxWidth}
    >
      {isLoading && (
        <Loading
          loadingPrimary=""
          loadingSecondary=""
          className={styles.center}
        />
      )}
      <div className={isLoading ? styles.blurred : styles.visible}>
        {title && (
          <DialogTitle>
            {onClose && (
              <IconButton
                aria-label="close"
                onClick={onClose}
                className={styles.icon}
              >
                <Close />
              </IconButton>
            )}
            {title}
          </DialogTitle>
        )}
        <DialogContent>{children}</DialogContent>
      </div>
    </DialogComponent>
  );
};

export default Modal;
