import * as React from "react";
import { AppBar, IconButtonWithTooltip } from "react-admin";
import { Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import { useTranslate } from "ra-core";
import { UserMenu } from "react-admin";
import { MenuItem } from '@material-ui/core'
import byCSWhite from '../../../assets/bycs-white.png'
import dataIntermediaryProvider from "../../../synapse/intermediaryDataProvider";
import {useEffect, useState} from "react";
import {capitalizeWords} from "../../../mixins/utils";

const useStyles = makeStyles(theme => ({
  fullWidth: { width: "100%" },
  logo: { width: "70px", paddingRight: "18px" },
  help: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.primary.contrastText,
  },
    appBar: {
      backgroundColor: theme.palette.primary.main,
    }
}));

/**
 * @description Custom AppBar Component based on REACT.MUI.APPBAR
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomAppBar = props => {
  const { fullWidth, logo, help, appBar } = useStyles();
  const translate = useTranslate();

  const goToTutorials = () => {
    const link = document.createElement("a");
    link.href = "https://www.bycs.de/themenbereich/messenger/admin-portal/index.html";
    link.rel = "noreferrer noopener";
    link.target = "_blank";
    link.click();
  };



    const [userName, setUserName] = useState('');

    useEffect(() => {
        const getUserName = async () => {
            const userID = localStorage.getItem("userId");
            const user = await dataIntermediaryProvider.getOne("users", { id: userID });
            return `${capitalizeWords(user?.data.data.first_name)} ${capitalizeWords(user?.data.data.last_name)}`;
        };

        getUserName().then(userName => {
            setUserName(userName);
        });
    }, []);

    const userNameMenuItem = <MenuItem disabled={true}>{userName}</MenuItem>

    return (
      <AppBar {...props} className={appBar} userMenu={<UserMenu  {...props} children={userNameMenuItem}/>}>
      <Link
        href="https://www.bycs.de/index.html"
        rel="noreferrer"
        target="_blank"
        underline="none"
      >
        <img src={byCSWhite} className={logo} alt="logo" />
      </Link>

      <Typography
        variant="h6"
        color="inherit"
        id="react-admin-title"
        className={fullWidth}
      />
            <IconButtonWithTooltip
                label={translate("synapseadmin.appbar.help_tooltip")}
                className={help}
                onClick={goToTutorials}
            >
                <HelpIcon />
            </IconButtonWithTooltip>
    </AppBar>
  );
};
