import React from "react";
import { Admin, Resource, resolveBrowserLocale } from "react-admin";
import polyglotI18nProvider from "ra-i18n-polyglot";
import authProvider from "./synapse/authProvider";
import dataIntermediaryProvider from "./synapse/intermediaryDataProvider";
import { UserList, UserEdit } from "./components/users";
import {
  PermissionsEdit,
  UserRolesPermissions,
} from "./components/userRolesPermissions";
import LoginPage from "./components/LoginPage";
import germanMessages from "./i18n/de";
import englishMessages from "./i18n/en";
import chineseMessages from "./i18n/zh";
import CustomLayout from "./components/custom/layout/CustomLayout";
import { CustomRoutes } from "./components/custom/layout/CustomRoutes";
import { CustomTheme } from "./components/custom/layout/CustomTheme";
import CustomFooter from "./components/custom/layout/CustomFooter";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Person from "@material-ui/icons/Person";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// TODO: Can we use lazy loading together with browser locale?
const messages = {
  de: germanMessages,
  en: englishMessages,
  zh: chineseMessages,
};
const i18nProvider = polyglotI18nProvider(
  locale => (messages[locale] ? messages[locale] : messages.de),
  resolveBrowserLocale()
);

const App = () => (
  <>
    <Admin
      layout={CustomLayout}
      theme={CustomTheme}
      disableTelemetry
      loginPage={LoginPage}
      authProvider={authProvider}
      dataProvider={dataIntermediaryProvider}
      i18nProvider={i18nProvider}
      customRoutes={CustomRoutes}
    >
      <Resource name="users" list={UserList} edit={UserEdit} icon={Person} />

      <Resource
        name="roles_permissions"
        list={UserRolesPermissions}
        edit={PermissionsEdit}
        icon={AssignmentIndIcon}
      />
      <Resource name="servernotices" />
      <Resource name="devices" />
      <CustomFooter />
      <ToastContainer
        position="bottom-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="colored"
        style={{ fontFamily: "Atkinson Hyperlegible" }}
      />
    </Admin>
  </>
);
export default App;
