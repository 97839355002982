import React, {useEffect, useState} from "react";
import {
    Typography,
    FormGroup,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import TimeInput from "../form/TimeInput";
import {useFormState} from "react-final-form";
import {useTranslate} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import InfoCard from "./InfoCard";


const useStyles = makeStyles((theme) => ({
    timeWrapper: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop:'1rem',
    },
    timeItem:{
        width: "30%",
    },
    errorState: {
        backgroundColor: 'red'
    }
}));


const SilentNotifications = (props) => {
    const { values } = useFormState();
    const classes = useStyles();
    const translate = useTranslate();
    const sN = values.silent_notifications

    const isInitalSNValue = (silentNotifications, weekday, field) => {
        if (!silentNotifications || !silentNotifications[weekday] || silentNotifications[weekday].length === 0) return ""

        const firstTimeFrame = silentNotifications[weekday][0]
        const secondTimeFrame = silentNotifications[weekday][1]

        if (secondTimeFrame) {
            return field === "to" ? secondTimeFrame["from"] : firstTimeFrame["to"]
        }

        if (firstTimeFrame["to"] === "23:59") {
            return field === "to" ? firstTimeFrame["from"] : "00:00"
        }

        if (firstTimeFrame["from"] === "00:00") {
            return field === "to" ? "23:59" : firstTimeFrame["to"]
        }

        return ""
    }

    const [isWeekendOff, setIsWeekendOff] = useState(sN?.allow_notifications_on_weekends)
    const [reqBody, setReqBody] = useState(null)
    const [selectedStartTime, setSelectedStartTime] = useState({
        0: isInitalSNValue(sN, 'mon', 'from'),
        1: isInitalSNValue(sN, 'tue', 'from'),
        2: isInitalSNValue(sN, 'wed', 'from'),
        3: isInitalSNValue(sN, 'thu', 'from'),
        4: isInitalSNValue(sN, 'fri', 'from'),
    });

    const [selectedEndTime, setSelectedEndTime] = useState({
        0: isInitalSNValue(sN, 'mon', 'to'),
        1: isInitalSNValue(sN, 'tue', 'to'),
        2: isInitalSNValue(sN, 'wed', 'to'),
        3: isInitalSNValue(sN, 'thu', 'to'),
        4: isInitalSNValue(sN, 'fri', 'to'),
    });

    const [errorState, setErrorState] = useState({
    0: { start: false, end: false },
    1: { start: false, end: false },
    2: { start: false, end: false },
    3: { start: false, end: false },
    4: { start: false, end: false },
    });
    const NUMBER_WORKING_DAYS = 5
    const checkFormState = () => {
        const newErrorState = {};
        for (let i = 0; i < NUMBER_WORKING_DAYS; i++) {
            const isStartBiggerThanEnd = selectedStartTime[i] > selectedEndTime[i]
            const isStartEqualEnd = selectedStartTime[i] === selectedEndTime[i] && selectedEndTime[i] !== ""
            const isOnlyEndSelected = selectedStartTime[i] === "" && selectedEndTime[i] !== ""
            const isOnlyStartSelected = selectedStartTime[i] !== "" && selectedEndTime[i] === ""

            newErrorState[`${i}.start`] = isOnlyStartSelected || isStartBiggerThanEnd || isStartEqualEnd;
            newErrorState[`${i}.end`] =  isOnlyEndSelected || isStartBiggerThanEnd || isStartEqualEnd;
        }
        setErrorState(newErrorState);
    };

    useEffect(() => {
        checkFormState()
        const payload = {
            allow_notifications_on_weekends: isWeekendOff,
            mon: (selectedStartTime[0] !== '' && selectedEndTime[0] !== '') ? checkSnStartEndInput(0) : [],
            tue: (selectedStartTime[1] !== '' && selectedEndTime[1] !== '') ? checkSnStartEndInput(1) : [],
            wed: (selectedStartTime[2] !== '' && selectedEndTime[2] !== '') ? checkSnStartEndInput(2) : [],
            thu: (selectedStartTime[3] !== '' && selectedEndTime[3] !== '') ? checkSnStartEndInput(3) : [],
            fri: (selectedStartTime[4] !== '' && selectedEndTime[4] !== '') ? checkSnStartEndInput(4) : []
        }
        setReqBody(payload)
    }, [isWeekendOff, selectedStartTime, selectedEndTime])

        const giveSnErrorStatus = () => {
            for (let i = 0; i < NUMBER_WORKING_DAYS; i++) {
                if (errorState[`${i}.start`] || errorState[`${i}.end`]) {
                    return true;
                }
            }
            return false;
        }

        useEffect(()=>{
        props.lifter(reqBody,giveSnErrorStatus())
        },[reqBody])

    const handleStartTimeChange = (e, i) => {
        const state = {...selectedStartTime}
        state[i] = e.target.value
        setSelectedStartTime(state)
    }
    const handleEndTimeChange = (e, i) => {
        const state = {...selectedEndTime}
        state[i] = e.target.value
        setSelectedEndTime(state)
    }

    const weekDays = [
        {id: 0, name: "mon", start: "08:00", end: "16:00", status: true},
        {id: 1, name: "tue", start: "08:00", end: "16:00", status: true},
        {id: 2, name: "wed", start: "08:00", end: "16:00", status: true},
        {id: 3, name: "thu", start: "08:00", end: "16:00", status: true},
        {id: 4, name: "fri", start: "08:00", end: "16:00", status: true},
    ];

    const checkSnStartEndInput = (weekday) => {
        const start = selectedStartTime[weekday]
        const end = selectedEndTime[weekday]
        let timeRangeArray = []

        if (start === "00:00" && end !== "23:59") {
            timeRangeArray.push({
                from: end,
                to: "23:59",
            })
        } else if (end === "23:59" && start !== "00:00") {
            timeRangeArray.push({
                from: "00:00",
                to: start
            })
        } else if (start < end && end !== "23:59" && start !== "00:00") {
            timeRangeArray.push({
                    from: "00:00",
                    to: start
                },
                {
                    from: end,
                    to: "23:59"
                })
        }

        return timeRangeArray
    }

    const toggleWeekendNotifications = (event) => {
        setIsWeekendOff(event.target.checked)
    }

    const showErrorStyle = (dayIndex,position) => {
        return errorState[`${dayIndex}.${position}`] && classes.errorState
    }

    return (
        <>
            <InfoCard text={translate("synapseadmin.users.silent_notifications.info")} width={"80%"}/>
            <div className={classes.timeWrapper}>
                <Typography variant={"h6"} className={classes.timeItem}>
                    {translate("synapseadmin.users.silent_times")}
                </Typography>
                <Typography variant={"h6"} className={classes.timeItem}>
                    {translate("synapseadmin.users.silent_notifications.start")}
                </Typography>
                <Typography variant={"h6"} className={classes.timeItem}>
                    {translate("synapseadmin.users.silent_notifications.end")}
                </Typography>
            </div>
            {weekDays.map((day, i) => {
                return (
                    <div key={day.name + i} className={classes.timeWrapper}>
                        <Typography className={classes.timeItem}>
                            {translate(
                                `synapseadmin.users.silent_notifications.weekdays.${day.name}`
                            )}
                        </Typography>
                        <div className={`${classes.timeItem} ${showErrorStyle(i,'end')}`}>
                            <TimeInput
                                source={day.name + i}
                                label={translate("synapseadmin.users.silent_notifications.start")}
                                value={selectedStartTime[i]}
                                onChange={e => {
                                    handleStartTimeChange(e, i);
                                }}
                            />
                        </div>
                        <div className={`${classes.timeItem} ${showErrorStyle(i,'start')}`}>
                            <TimeInput
                                label={translate("synapseadmin.users.silent_notifications.end")}
                                value={selectedEndTime[i]}
                                onChange={e => {
                                    handleEndTimeChange(e, i);
                                }}
                            />
                        </div>
                    </div>
                );
            })}
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            color={"primary"}
                            checked={isWeekendOff || false}
                            onChange={toggleWeekendNotifications}
                        />
                    }
                    label={translate("synapseadmin.users.weekend_notifications")}
                />
            </FormGroup>
        </>
    );
}


export default SilentNotifications;
