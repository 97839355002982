import germanMessages from "ra-language-german";

const de = {
  ...germanMessages,
  synapseadmin: {
    appbar: {
      help_tooltip: "Hier finden Sie die Tutorials",
    },
    auth: {
      base_url: "Heimserver URL",
      welcome: "Willkommen beim ByCS Messenger Admin-Panel",
      server_version: "Synapse Version",
      username_error: "Bitte vollständigen Nutzernamen angeben: '@user:domain'",
      protocol_error: "Die URL muss mit 'http://' oder 'https://' beginnen",
      url_error: "Keine gültige Matrix Server URL",
      sso_sign_in: "Anmeldung mit ByCS",
      panelName: "ByCS-Messenger-Admin-Portal",
      imprint: "Impressum",
      dataProtection: "Datenschutzerklärung",
      conditions: "Nutzungsbedingungen",
      license: "Lizenz",
      log_again: "Bitte melde dich erneut an",
      not_admin: "Benutzer ist kein Administrator",
      not_found: "Benutzer nicht gefunden",
    },
    users: {
      success_msg: "Benutzer erfolgreich aktualisiert",
      success_msg_deactivation: "Benutzerstatus wurde erfolgreich aktualisiert",
      err_msg: "Benutzer konnte nicht aktualisiert werden",
      invalid_user_id: "Lokaler Anteil der Matrix Benutzer-ID ohne Homeserver.",
      tabs: { sso: "SSO" },
      permissions_info:
        "Ihre Änderungen werden nun vorgenommen. Abhängig von der Anzahl der Nutzerinnen und Nutzer kann dies einige Zeit in Anspruch nehmen. Sie können diese Seite bei Bedarf schließen, die Anpassungen werden trotzdem durchgeführt.",
      notifications: "Wählen Sie Verfügbarkeitszeiten",
      notifications_groups: "Wählen Sie Verfügbarkeitszeiten für ausgewählte Benutzer",
      silent_times: "Verfügbarkeit",
      weekend_notifications: "Wochenendbenachrichtigungen",
      silent_notifications: {
        info: "Beispiel: Damit die Nutzerinnen und Nutzer an einem bestimmten Wochentag nur zwischen 08:00 und 18:00 Uhr Benachrichtigungen über den Messenger erhalten, tragen Sie folgende Verfügbarkeitszeiten ein: Beginn 08:00, Ende 18:00.",
        warning_msg:
          "Achtung! Möchten Sie wirklich schulweit die Verfügbarkeitszeiten ändern? Dadurch werden alle vorherigen Einstellungen (für Rollen, Gruppen und Personen) überschrieben.",
        success_msg: "Ruhezeiten erfolgreich aktualisiert",
        err_msg: "Ruhezeiten konnte nicht aktualisiert werden",
        start: "Beginn",
        end: "Ende",
        weekend: "Wochenende",
        weekday: "Wochentag",
        weekdays: {
          mon: "Montag",
          tue: "Dienstag",
          wed: "Mittwoch",
          thu: "Donnerstag",
          fri: "Freitag",
        },
      },
    },
    rooms: {
      details: "Raumdetails",
      tabs: {
        basic: "Allgemein",
        members: "Mitglieder",
        detail: "Details",
        permission: "Berechtigungen",
      },
    },
    reports: { tabs: { basic: "Allgemein", detail: "Details" } },
  },
  import_users: {
    error: {
      at_entry: "Bei Eintrag %{entry}: %{message}",
      error: "Fehler",
      required_field: "Pflichtfeld '%{field}' fehlt",
      invalid_value:
        "Ungültiger Wert in Zeile %{row}. Feld '%{field}' darf nur die Werte 'true' oder 'false' enthalten",
      unreasonably_big: "Datei ist zu groß für den Import (%{size} Megabytes)",
      already_in_progress: "Es läuft bereits ein Import",
      id_exits: "ID %{id} existiert bereits",
    },
    title: "Benutzer aus CSV importieren",
    goToPdf: "Gehe zum PDF",
    cards: {
      importstats: {
        header: "Benutzer importieren",
        users_total:
          "%{smart_count} Benutzer in der CSV Datei |||| %{smart_count} Benutzer in der CSV Datei",
        guest_count: "%{smart_count} Gast |||| %{smart_count} Gäste",
        admin_count:
          "%{smart_count} Server Administrator |||| %{smart_count} Server Administratoren",
      },
      conflicts: {
        header: "Konfliktstrategie",
        mode: {
          stop: "Stoppe bei Fehlern",
          skip: "Zeige Fehler und überspringe fehlerhafte Einträge",
        },
      },
      ids: {
        header: "IDs",
        all_ids_present: "IDs in jedem Eintrag vorhanden",
        count_ids_present:
          "%{smart_count} Eintrag mit ID |||| %{smart_count} Einträge mit IDs",
        mode: {
          ignore: "Ignoriere IDs der CSV-Datei und erstelle neue",
          update: "Aktualisiere existierende Benutzer",
        },
      },
      passwords: {
        header: "Passwörter",
        all_passwords_present: "Passwörter in jedem Eintrag vorhanden",
        count_passwords_present:
          "%{smart_count} Eintrag mit Passwort |||| %{smart_count} Einträge mit Passwörtern",
        use_passwords: "Verwende Passwörter aus der CSV Datei",
      },
      upload: {
        header: "CSV Datei importieren",
        explanation:
          "Hier können Sie eine Datei mit kommagetrennten Daten hochladen, die verwendet werden um Benutzer anzulegen oder zu ändern. Die Datei muss mindestens die Felder 'id' und 'displayname' enthalten. Hier können Sie eine Beispieldatei herunterladen und anpassen: ",
      },
      startImport: {
        simulate_only: "Nur simulieren",
        run_import: "Importieren",
      },
      results: {
        header: "Ergebnis",
        total:
          "%{smart_count} Eintrag insgesamt |||| %{smart_count} Einträge insgesamt",
        successful: "%{smart_count} Einträge erfolgreich importiert",
        skipped: "%{smart_count} Einträge übersprungen",
        download_skipped: "Übersprungene Einträge herunterladen",
        with_error:
          "%{smart_count} Eintrag mit Fehlern ||| %{smart_count} Einträge mit Fehlern",
        simulated_only: "Import-Vorgang war nur simuliert",
      },
    },
  },
  resources: {
    roles_permissions: {
      warning_msg:
        "Achtung! Möchten Sie wirklich schulweit die Rechte ändern? Dadurch werden alle vorherigen" +
        " Einstellungen (für Rollen, Gruppen und Personen) überschrieben.",
      name: "Rollen & Rechte",
      hint:
        "Beachten Sie bitte, dass es nicht zwingend erforderlich ist, sowohl eine Rolle als auch eine" +
        ' Klasse/Gruppe auszuwählen. Wenn Sie beispielsweise die Rolle "Schüler" auswählen, ohne eine Klasse zu wählen, werden alle Schüler der Schule angezeigt.',
      add_guest_to_room: "Gast zum Raum hinzufügen",
      attachment_forward: "Weiterleiten und teilen",
      attachment_send: "Anhang senden",
      block_users: "Benutzer blockieren",
      camera_access: "Bilder/Videos aufnehmen",
      delete_message: "Nachricht löschen",
      edit_message: "Nachricht bearbeiten",
      emergency_message: "Notfallnachricht",
      file_access: "Anhang senden",
      gallery_access: "Galeriezugriff",
      invite_guests: "Benutzer zu Chat hinzufügen",
      location_share: "Standort teilen",
      manage_rooms: "Chats erstellen/verwalten",
      voice_message: "Sprachnachrichten senden",
      search_users: "Benutzer suchen",
      send_message: "Nachricht senden",
      video_call: "Videoanruf",
      voice_call: "Sprachanruf",
      permissions: "Rechte",
      toggle_all: "Alle umschalten",
      apply: "Anwenden",
      bulkchange: "Rollen- und Gruppenauswahl",
      set_permissions: "Berechtigungen festlegen",
      select_roles_groups: "Rollen- und Gruppenauswahl",
      user_role: "Nutzerrolle",
      user_group: "Klasse/Gruppe",
      next: "Fortfahren",
      activate_picked_users: "Ausgewählte Benutzer deaktivieren",
      activate_users: "Benutzer deaktivieren",
      actions: {
        update_success: "Rechte erfolgreich aktualisiert.",
      },
    },
    users: {
      name: "Benutzer",
      email: "E-Mail",
      msisdn: "Telefon",
      threepid: "E-Mail / Telefon",
      sucess_msg: "Benutzer in Schule erfolgreich aktualisiert.",
      success_msg_restriction: "Zugriffsbeschränkung erfolgreich aktualisiert",
      success_msg_deactivation: "(De-)Aktivierungen erfolgreich aktualisiert",
      success_msg_permissions: "Berechtigungen erfolgreich aktualisiert",
      err_msg: "Aktualisierung konnte nicht durchgeführt werden",
      show_all: "Alle Benutzer",
      search_placeholder: "Personen suchen",
      fields: {
        avatar: "Profilbild",
        first_name: "Vorname",
        last_name: "Nachname",
        uid: "ByCS-Kennung",
        name: "Name",
        username: "ByCS-Kennung",
        role: "Rolle",
        is_guest: "Gast",
        admin: "Server Administrator",
        deactivated: "Deaktiviert",
        guests: "Zeige Gäste",
        show_deactivated: "Zeige deaktivierte Benutzer",
        user_id: "Suche Benutzer",
        displayname: "Anzeigename",
        password: "Passwort",
        avatar_url: "Avatar URL",
        avatar_src: "Avatar",
        medium: "Medium",
        threepids: "3PIDs",
        address: "Adresse",
        creation_ts_ms: "Zeitpunkt der Erstellung",
        consent_version: "Zugestimmte Geschäftsbedingungen",
        auth_provider: "Provider",
      },
      helper: {
        deactivate:
          "Sie müssen ein Passwort angeben, um ein Konto wieder zu aktivieren.",
        erase: "DSGVO konformes Löschen der Benutzerdaten",
      },
      action: {
        erase: "Lösche Benutzerdaten",
      },
    },
    user_roles: {
      name: "Benutzerrollen",
    },
    user_groups: {
      name: "Benutzergruppen",
    },
    rooms: {
      name: "Raum |||| Räume",
      fields: {
        room_id: "Raum-ID",
        name: "Name",
        canonical_alias: "Alias",
        joined_members: "Mitglieder",
        joined_local_members: "Lokale Mitglieder",
        joined_local_devices: "Lokale Endgeräte",
        state_events: "Zustandsereignisse / Komplexität",
        version: "Version",
        is_encrypted: "Verschlüsselt",
        encryption: "Verschlüsselungs-Algorithmus",
        federatable: "Fö­de­rierbar",
        public: "Sichtbar im Raumverzeichnis",
        creator: "Ersteller",
        join_rules: "Beitrittsregeln",
        guest_access: "Gastzugriff",
        history_visibility: "Historie-Sichtbarkeit",
        topic: "Thema",
        avatar: "Avatar",
      },
      helper: {
        forward_extremities:
          "Forward extremities are the leaf events at the end of a Directed acyclic graph (DAG) in a room, aka events that have no children. The more exist in a room, the more state resolution that Synapse needs to perform (hint: it's an expensive operation). While Synapse has code to prevent too many of these existing at one time in a room, bugs can sometimes make them crop up again. If a room has >10 forward extremities, it's worth checking which room is the culprit and potentially removing them using the SQL queries mentioned in #1760.",
      },
      enums: {
        join_rules: {
          public: "Öffentlich",
          knock: "Auf Anfrage",
          invite: "Nur auf Einladung",
          private: "Privat",
        },
        guest_access: {
          can_join: "Gäste können beitreten",
          forbidden: "Gäste können nicht beitreten",
        },
        history_visibility: {
          invited: "Ab Einladung",
          joined: "Ab Beitritt",
          shared: "Ab Setzen der Einstellung",
          world_readable: "Jeder",
        },
        unencrypted: "Nicht verschlüsselt",
      },
      action: {
        erase: {
          title: "Raum löschen",
          content:
            "Sind Sie sicher dass Sie den Raum löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden. Alle Nachrichten und Medien, die der Raum beinhaltet werden vom Server gelöscht!",
        },
      },
    },
    reports: {
      name: "Ereignisbericht |||| Ereignisberichte",
      fields: {
        id: "ID",
        received_ts: "Meldezeit",
        user_id: "Meldender",
        name: "Raumname",
        score: "Wert",
        reason: "Grund",
        event_id: "Event-ID",
        event_json: {
          origin: "Ursprungsserver",
          origin_server_ts: "Sendezeit",
          type: "Eventtyp",
          content: {
            msgtype: "Inhaltstyp",
            body: "Nachrichteninhalt",
            format: "Nachrichtenformat",
            formatted_body: "Formatierter Nachrichteninhalt",
            algorithm: "Verschlüsselungsalgorithmus",
          },
        },
      },
    },
    connections: {
      name: "Verbindungen",
      fields: {
        last_seen: "Datum",
        ip: "IP-Adresse",
        user_agent: "User Agent",
      },
    },
    devices: {
      name: "Gerät |||| Geräte",
      fields: {
        user_id: "Benutzer",
        device_id: "Geräte-ID",
        display_name: "Gerätename",
        last_seen_ts: "Zeitstempel",
        last_seen_ip: "IP-Adresse",
        last_seen_user_agent: "Zuletzt gesehener Benutzeragent",
      },
      action: {
        erase: {
          title: "Entferne %{id}",
          content: 'Möchten Sie das Gerät "%{name}" wirklich entfernen?',
          success: "Gerät erfolgreich entfernt.",
          failure: "Beim Entfernen ist ein Fehler aufgetreten.",
        },
      },
    },
    users_media: {
      name: "Medien",
      fields: {
        media_id: "Medien ID",
        media_length: "Größe",
        media_type: "Typ",
        upload_name: "Dateiname",
        quarantined_by: "Zur Quarantäne hinzugefügt",
        safe_from_quarantine: "Schutz vor Quarantäne",
        created_ts: "Erstellt",
        last_access_ts: "Letzter Zugriff",
      },
    },
    delete_media: {
      name: "Medien",
      fields: {
        before_ts: "Letzter Zugriff vor",
        size_gt: "Größer als (in Bytes)",
        keep_profiles: "Behalte Profilbilder",
      },
      action: {
        send: "Medien löschen",
        send_success: "Anfrage erfolgreich versendet.",
        send_failure: "Beim Versenden ist ein Fehler aufgetreten.",
      },
      helper: {
        send: "Diese API löscht die lokalen Medien von der Festplatte des eigenen Servers. Dies umfasst alle lokalen Miniaturbilder und Kopien von Medien. Diese API wirkt sich nicht auf Medien aus, die sich in externen Medien-Repositories befinden.",
      },
    },
    protect_media: {
      action: {
        create: "Ungeschützt, Schutz erstellen",
        delete: "Geschützt, Schutz aufheben",
        none: "In Quarantäne",
        send_success: "Erfolgreich den Schutz-Status geändert.",
        send_failure: "Beim Versenden ist ein Fehler aufgetreten.",
      },
    },
    quarantine_media: {
      action: {
        name: "Quarantäne",
        create: "Zur Quarantäne hinzufügen",
        delete: "In Quarantäne, Quarantäne aufheben",
        none: "Geschützt vor Quarantäne",
        send_success: "Erfolgreich den Quarantäne-Status geändert.",
        send_failure: "Beim Versenden ist ein Fehler aufgetreten.",
      },
    },
    pushers: {
      name: "Pusher |||| Pushers",
      fields: {
        app: "App",
        app_display_name: "App-Anzeigename",
        app_id: "App ID",
        device_display_name: "Geräte-Anzeigename",
        kind: "Art",
        lang: "Sprache",
        profile_tag: "Profil-Tag",
        pushkey: "Pushkey",
        data: { url: "URL" },
      },
    },
    servernotices: {
      name: "Serverbenachrichtigungen",
      send: "Servernachricht versenden",
      fields: {
        body: "Nachricht",
      },
      action: {
        send: "Sende Nachricht",
        send_success: "Nachricht erfolgreich versendet.",
        send_failure: "Beim Versenden ist ein Fehler aufgetreten.",
      },
      helper: {
        send: 'Sendet eine Serverbenachrichtigung an die ausgewählten Nutzer. Hierfür muss das Feature "Server Notices" auf dem Server aktiviert sein.',
      },
    },
    user_media_statistics: {
      name: "Dateien je Benutzer",
      fields: {
        media_count: "Anzahl der Dateien",
        media_length: "Größe der Dateien",
      },
    },
    forward_extremities: {
      name: "Vorderextremitäten",
      fields: {
        id: "Event-ID",
        received_ts: "Zeitstempel",
        depth: "Tiefe",
        state_group: "Zustandsgruppe",
      },
    },
    room_state: {
      name: "Zustandsereignisse",
      fields: {
        type: "Typ",
        content: "Inhalt",
        origin_server_ts: "Sendezeit",
        sender: "Absender",
      },
    },
    room_directory: {
      name: "Raumverzeichnis",
      fields: {
        world_readable: "Gastbenutzer dürfen ohne Beitritt lesen",
        guest_can_join: "Gastbenutzer dürfen beitreten",
      },
      action: {
        title:
          "Raum aus Verzeichnis löschen |||| %{smart_count} Räume aus Verzeichnis löschen",
        content:
          "Möchten Sie den Raum wirklich aus dem Raumverzeichnis löschen? |||| Möchten Sie die %{smart_count} Räume wirklich aus dem Raumverzeichnis löschen?",
        erase: "Lösche aus Verzeichnis",
        create: "Eintragen ins Verzeichnis",
        send_success: "Raum erfolgreich eingetragen.",
        send_failure: "Beim Entfernen ist ein Fehler aufgetreten.",
      },
    },
    registration_tokens: {
      name: "Registrierungstoken",
      fields: {
        token: "Token",
        valid: "Gültige Token",
        uses_allowed: "Anzahl",
        pending: "Ausstehend",
        completed: "Abgeschlossen",
        expiry_time: "Ablaufzeit",
        length: "Länge",
      },
      helper: { length: "Länge des Tokens, wenn kein Token vorgegeben wird." },
    },
    import_users: {
      name: "Nutzer importieren",
    },
    school_settings: {
      name: "Schuleinstellungen",
      avatar: {
        success_msg: "Logo erfolgreich aktualisiert",
        err_msg: "Logo konnte nicht aktualisiert werden",
        upload_header:
          "Klicken oder ziehen Sie Dateien in diesen Bereich, um sie hochzuladen",
        upload_text:
          "Unterstützung für einen einfachen oder Massen-Upload." +
          " Das Hochladen von Firmendaten oder andere Banddateien ist strengstens untersagt.",
        input: "Logo der Schule",
        description:
          "Hier können Sie das Logo oder ein Bild Ihrer Einrichtung hochladen. Im Messenger wird dieses" +
          " dann als Logo eingebunden.",
      },
      access_restriction: {
        label: "Zugriffsbeschränkung",
        description:
          "Durch das Aktivieren der Zugriffsbeschränkung wird auf den mobilen Endgeräten aller Nutzer Ihrer Schule ein Sperrcode für die App erzwungen. Die nutzende Person muss diesen selbst festlegen und dann jedes Mal beim Starten der App eingeben.",
        input: "Sperrcode erzwingen",
        modalText:
          "Möchten Sie wirklich die Zugriffsbeschränkung für alle Nutzer Ihrer Schule ändern?",
        yes: "Ja",
        no: "Nein",
        attention: "Achtung!",
      },
      deactivation: {
        label: "Alle Schulbenutzer deaktivieren",
        title: "Alle Nutzer (de-)aktivieren",
        description:
          "Bitte beachten Sie, dass sich bei einer Deaktivierung kein Nutzer Ihrer Einrichtung mehr im Messenger anmelden kann.",
        input: "Deaktivieren",
        modalText:
          "Möchten Sie wirklich alle Nutzer Ihrer Schule (de-)aktivieren?",
        modalTitle: "Nutzer (de-)aktivieren",
      },
    },
  },
  ra: {
    ...germanMessages.ra,
    action: {
      ...germanMessages.ra.action,
      unselect: "Abwählen",
    },
    auth: {
      ...germanMessages.ra.auth,
      auth_check_error: "Anmeldung fehlgeschlagen",
    },
    input: {
      ...germanMessages.ra.input,
      password: {
        ...germanMessages.ra.input.password,
        toggle_hidden: "Anzeigen",
        toggle_visible: "Verstecken",
      },
    },
    notification: {
      ...germanMessages.ra.notification,
      logged_out: "Abgemeldet",
    },
    page: {
      ...germanMessages.ra.page,
      empty: "Keine Einträge vorhanden",
      invite: "",
    },
    navigation: {
      ...germanMessages.ra.navigation,
      skip_nav: "Zum Inhalt springen",
    },
  },
};
export default de;
