import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  footer: () => ({
    fontFamily: theme.typography.fontFamily,
    position: "fixed",
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    padding: 6,
    backgroundColor: "#efefef",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  }),
  list: () => ({
    margin: "0 1rem",
    color: "#264D7F",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap",
      margin: ".2rem 0",
      marginLeft: "0.6rem",
    },
  }),
}));

function CustomFooter() {
  const styles = useStyles();
  const translate = useTranslate();

  const links = [
    {
      text: translate("synapseadmin.auth.imprint"),
      link: "https://www.bycs.de/impressum/index.html",
    },
    {
      text: translate("synapseadmin.auth.dataProtection"),
      link: "https://www.bycs.de/uebersicht-und-funktionen/messenger/datenschutzerklaerung/index.html",
    },
    {
      text: translate("synapseadmin.auth.conditions"),
      link: "https://www.bycs.de/uebersicht-und-funktionen/messenger/nutzungsbedingungen/index.html",
    },
  ];

  return (
    <footer className={styles.footer}>
      <div>
        <Typography variant="p" className={styles.list}>
          {translate("synapseadmin.auth.panelName")}
        </Typography>
      </div>
      <div>
        {links.map((link, idx) => {
          return (
            <Link
              className={styles.list}
              key={idx}
              href={link.link}
              rel="noreferrer"
              target="_blank"
              underline="none"
            >
              {link.text}
            </Link>
          );
        })}
      </div>
    </footer>
  );
}

export default CustomFooter;
