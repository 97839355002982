import React, { useEffect } from "react";
import {
  useNotify,
  useTranslate,
  useRedirect, Notification,
} from "react-admin";
import { Form } from "react-final-form";
import {
  Button,
  Card,
  CardActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomFooter from "./custom/layout/CustomFooter";
import dataIntermediaryProvider from "../synapse/intermediaryDataProvider";
//import loadAmpel from "../mixins/matrix_ampel.js";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#264D7F",
  },
  trafficLight: {
    position: "absolute",
    transform: "translate(300px, 100px)",
  },
  card: {
    minWidth: "30em",
    marginTop: "6em",
    marginBottom: "6em",
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
    fontFamily: theme.typography.fontFamily,
  },
  form: {
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "2em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  serverVersion: {
    color: "#9e9e9e",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    marginBottom: "1em",
    marginLeft: "0.5em",
  },
  logo: {
    objectFit: "contain",
    width: "84px",
    height: "84px",
    padding: "8px",
    margin: "16px 0 0 0",
  },
  centered: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  btnWrapper: {
    marginTop: "2rem",
    width: "100%",
  },
  ssoBtn: {
    backgroundColor: "#264D7F",
    color: "white",
  },
}));

const LoginPage = ({ theme }) => {
  const classes = useStyles({ theme });
  const notify = useNotify();
  const translate = useTranslate();
  const ssoBaseUrl = window.ENV.intermediary_endpoint
  const code = +localStorage.getItem('auth_code')
  const isLoggedIn = localStorage.getItem('isLoggedIn')
  const redirect =  useRedirect()
  const errorCodes = {
    401: translate("synapseadmin.auth.log_again"),
    403: translate("synapseadmin.auth.not_admin"),
    404: translate("synapseadmin.auth.not_found"),
  };
  code && notify(errorCodes[code], "warning");


  useEffect(_ => {
    // Hidden for Pilot1 comes back for Pilot2
    // const remote = 'https://alerting.staging-bycsmessenger.dev/ampel/matrix';
    // const container = document.getElementsByClassName(classes.trafficLight)[0];
    // const trafficLight = document.getElementById('ampel-container-internal');
    // const ampelPing = loadAmpel(container, 40, 'right', 1000, remote);
    if (isLoggedIn) {
      saveUserIdInLocalStorage();
      redirect("/users");
      // Hidden for Pilot1 comes back for Pilot2
      // clearInterval(ampelPing)
    }
  }, []);


  const saveUserIdInLocalStorage = () => {
    const token = localStorage.getItem("auth_token_intermediary");
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    const json = JSON.parse(jsonPayload);
    localStorage.setItem("userId", json.data.user_id);
  };

  const handleSubmit = auth => {
    auth.preventDefault()
  };

  const handleSSO = () => {
    localStorage.removeItem('auth_code');
    localStorage.setItem("sso_base_url", ssoBaseUrl);
    const urlAfterAuth = `?app_domain=${window.location.origin}`

    const ssoFullUrl = `${ssoBaseUrl}/api/admin/auth` + urlAfterAuth;
    window.location.href = ssoFullUrl;

  };

  return (
    <>
      <Form
          render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.main}>
                  <div className={classes.trafficLight} />
                  <Card className={classes.card}>
                    <div className={classes.centered}>
                      <img src={process.env.REACT_APP_BYCS_LOGO} className={classes.logo} alt="logo" />
                    </div>
                    <div className={classes.hint}>
                      {translate("synapseadmin.auth.welcome")}
                    </div>

                    <CardActions className={classes.actions}>
                      <div className={classes.btnWrapper}>
                        <Button
                            variant="contained"
                            onClick={handleSSO}
                            className={classes.ssoBtn}
                            fullWidth
                        >
                          {translate("synapseadmin.auth.sso_sign_in")}
                        </Button>
                      </div>
                    </CardActions>
                  </Card>
                </div>
              </form>
            )}
          onSubmit={handleSubmit}
      />
      <CustomFooter />
      <Notification/>
    </>
  );
};

export default LoginPage;
