import {fetchUtils} from "react-admin";


const setIsLoggedIn = () => {
  const authToken = localStorage.getItem('auth_token_intermediary')
  if(authToken !== ''){
    localStorage.setItem('isLoggedIn',true);
  }
}
const checkKeycloakToken = () => {
  const queryParamsPresent = window.location.search
  if(queryParamsPresent){
    const newAuthToken = window.location.search.replace('?intermediary_token=','');
    localStorage.setItem('auth_token_intermediary',newAuthToken)
    setIsLoggedIn()
  }
};

const removeTokenFromUrl = () => {
  if(window.location.search.includes('?intermediary_token=')){
    window.history.replaceState(null, null, window.location.origin + '/#/login');
  }
};

const checkErrorParams = () => {
  const queryParamsPresent = window.location.search
  if(queryParamsPresent){
    const params = window.location.search.split('?')[1].split('&')
    const code = params[0].replace('code=','')
    localStorage.setItem('auth_code',code)

  }
};

const removeOldStatusCode = () => {
  if(window.location.search.includes('?code=')){
    window.history.replaceState(null, null, window.location.origin + '/#/login');
  }
}

const authProvider = {
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("isLoggedIn");
    const ssoBaseUrl = window.ENV.intermediary_endpoint;
    const ssoBaseUrlStorage =  localStorage.getItem("sso_base_url");

    console.log("logout");
    const logout_api_url = (ssoBaseUrl || ssoBaseUrlStorage) + "/api/admin/logout";
    const access_token = localStorage.getItem("auth_token_intermediary");

    const options = {
      method: "POST",
      user: {
        authenticated: true,
        token: `Bearer ${access_token}`,
      },
    };

    if (typeof access_token === "string") {
      fetchUtils.fetchJson(logout_api_url, options).then(({ json }) => {
        localStorage.removeItem("auth_token_intermediary");
      });
    }
    localStorage.removeItem("auth_token_intermediary");

    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    console.log("checkError " + status);
    if (status === 401 || status === 403) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    checkKeycloakToken()
    removeTokenFromUrl()
    checkErrorParams()
    removeOldStatusCode()
    const access_token = localStorage.getItem("auth_token_intermediary");
    return typeof access_token === "string"
        ? Promise.resolve()
        : Promise.reject({ message: false });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
