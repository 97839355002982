import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/react";

Sentry.init({
    dsn: "https://254b2e4bfe6343f08e5286f94c92bab5@errors.sdui.de/24",
    integrations: [
        new Sentry.Integrations.InboundFilters(),
        new BrowserTracing()
    ],
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV
});


ReactDOM.render(<App />, document.getElementById("root"));
