import {Button as MuiButton} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core"


const useStyles = makeStyles(theme => ({
    modal: () => ({
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 16px',
        fontFamily: theme.typography.fontFamily,
    }),
    buttons: () => ({
        color: 'whitesmoke',
        '& button': {
            width: '6rem',
            "&:nth-child(2)": {
                backgroundColor: theme.palette.error.dark,
                marginLeft: 8,
                color: theme.palette.primary.contrastText
            }
        }
    }),
}))
function CustomBooleanModal({title,text,successBtn,declineBtn, onSuccess,onDecline}) {
    const styles = useStyles();


    return (
        <Grid className={styles.modal}>
            <h2>{title}</h2>
            <p>{text}</p>
            <div className={styles.buttons}>
                <MuiButton variant="contained"
                           onClick={onSuccess}
                           color="primary">{successBtn}</MuiButton>
                <MuiButton onClick={onDecline}
                           variant="contained">{declineBtn}</MuiButton>
            </div>
        </Grid>
    );
}

export default CustomBooleanModal;
