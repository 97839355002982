import React, { useEffect, useState } from "react";
import {
  Datagrid,
  List,
  Button,
  TopToolbar,
  sanitizeListRestProps,
  SelectInput,
  SimpleForm,
  EditButton,
  Toolbar,
  useRefresh,
  useNotify,
  BooleanInput,
  BooleanField,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  SaveButton,
  Filter,
  SearchInput,
  Loading,
} from "react-admin";
import Modal from "./custom/Modal";

import { makeStyles } from "@material-ui/core/styles";
import dataIntermediaryProvider from "../synapse/intermediaryDataProvider";
import { useTranslate } from "ra-core";
import SwitchGroup from "./custom/form/SwitchGroup";
import SilentNotifications from "./custom/layout/SilentNotifications";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { capitalizeWords } from "../mixins/utils";
import { CustomTextField } from "./users";
import InfoCard from "./custom/layout/InfoCard";
import { UserPagination } from "./users";

const useStyles = makeStyles(theme => ({
  dropdown: () => ({
    width: "100%",
  }),
  bulkBtnWrapper: () => ({
    width: "100%",
    display: "flex",
    alignItems: "start",
    justifyContent: "flex-start",
  }),
  bulkBtn: () => ({
    position: "sticky",
    zIndex: 4,
    left: 280,
  }),
  searchField: () => ({
    position: "sticky",
    zIndex: 4,
    left: 8,
  }),
  actionBtn: () => ({
    textAlign: "center",
  }),
  fixElements: () => ({
    minWidth: 270,
    position: "sticky",
    left: 0,
    zIndex: 4,
    backgroundColor: theme.palette.background.paper,
  }),
  fixElementsSecond: () => ({
    minWidth: "fit-content",
    position: "sticky",
    left: 270,
    zIndex: 4,
    backgroundColor: theme.palette.background.paper,
  }),
  fixElementsThird: () => ({
    minWidth: "fit-content",
    position: "sticky",
    left: 370,
    zIndex: 4,
    backgroundColor: theme.palette.background.paper,
  }),
  fixElementsFourth: () => ({
    minWidth: "fit-content",
    position: "sticky",
    left: 470,
    zIndex: 4,
    backgroundColor: theme.palette.background.paper,
  }),
  modalTitles: () => ({
    fontFamily: theme.typography.fontFamily,
  }),
  scrollWrapper: () => ({
    width: "100%",
    height: "700px",
    overflow: "scroll",
  }),
  permissionsWrapper: () => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "1rem",
  }),
  permissionItem: () => ({
    height: "fit-content",
    margin: "1rem",
  }),
  leftPermissionItems: () => ({
    width: "25%",
  }),
  rightPermissionItems: () => ({
    width: "50%",
  }),
  toggleAllInput: () => ({
    margin: "2.5rem 0",
  }),
  individualSwitch: () => ({
    marginBottom: ".5rem",
  }),
  activateSwitch: () => ({
    marginTop: "3.5rem",
  }),
  greenStyle: () => ({
    color: "green",
  }),
  redStyle: () => ({
    color: "red",
  }),
  centerCheckMark: () => ({
    paddingLeft: "20%",
  }),
  capitalized: () => ({
    textTransform: "capitalize",
  }),
  description: () => ({
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    width: "50%",
    paddingLeft: ".5rem",
  }),
  center: () => ({
    width: "fit-content",
    height: "fit-content",
    position: "absolute",
    zIndex: "99",
    top: "50%",
    left: "50%",
  }),
  visible: () => ({
    width: "100%",
  }),
  blurred: () => ({
    width: "100%",
    filter: "blur(10px)",
  }),
  column: () => ({
    display: "flex",
    flexDirection: "column",
    width: "fit-content",
  }),
  permissionInfoWrapper: () => ({
    padding: "3rem 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  }),
}));

export const permissionsList = [
  "manage_rooms",
  "send_message",
  "edit_message",
  "delete_message",
  "voice_call",
  // "video_call", // left as an option for a client, please don't remove
  "camera_access",
  "voice_message",
  "location_share",
  // "attachment_send",
  "attachment_forward",
  "file_access",
  // "gallery_access",
  "invite_guests",
  // "add_guest_to_room",
  "search_users",
  "block_users",
  "emergency_message",
];

export const ColorBooleanField = props => {
  const { sortBy, property, record } = props;
  const styles = useStyles();

  return (
    <BooleanField
      source={property}
      sortBy={sortBy}
      className={`${styles.centerCheckMark} ${
        record[property] ? styles.greenStyle : styles.redStyle
      }`}
    />
  );
};

export const UserListActions = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  data,
  ...rest
}) => {
  const styles = useStyles();
  const t = useTranslate();

  /*
   * STATE
   * */
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPermissonSelectModal, setIsOpenPermissonSelectModal] =
    useState(false);
  const [roleChoices, setRoleChoices] = useState([]);
  const [groupChoices, setGroupChoices] = useState([]);
  const [pickedGroupUsers, setPickedGroupUsers] = useState([]);
  const [disableNextBtn, setDisableNextBtn] = useState(true);
  const [answers, setAnswers] = useState({
    role: [],
    group: [],
  });
  const [groupPermissions, setGroupPermissions] = useState({
    add_guest_to_room: false,
    attachment_forward: false,
    attachment_send: false,
    block_users: false,
    camera_access: false,
    delete_message: false,
    edit_message: false,
    emergency_message: false,
    file_access: false,
    gallery_access: false,
    invite_guests: false,
    location_share: false,
    manage_rooms: false,
    mic_access: false,
    search_users: false,
    send_message: false,
    video_call: false,
    voice_call: false,
  });
  const [usersPermitted, setUsersPermitted] = useState([]);
  const [showInfo, setShowInfo] = useState(false);

  /*
   * FUNCTIONS
   * */

  const showModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setAnswers({
      role: [],
      group: [],
    });
    setDisableNextBtn(true);
  };

  const showPermissonSelectModal = () => {
    setIsOpenPermissonSelectModal(true);
  };
  const closePermissonSelectModal = () => {
    setIsOpenPermissonSelectModal(false);
  };

  const showNextStep = e => {
    e.preventDefault();
    closeModal();
    showPermissonSelectModal();
  };

  const submitNewPermissions = async e => {
    setIsLoading(true);
    setShowInfo(true);
    e.preventDefault();

    await dataIntermediaryProvider.updatePermissions(
      groupPermissions,
      usersPermitted
    );

    await dataIntermediaryProvider
      .updateRestrictionActivationData(
        {
          users: usersPermitted,
          mebisDeactivated: deactivateUsers,
        },
        deactivateUsers,
        "success_msg_deactivation"
      )
      .then(() => {
        setDeactivateUsers(false);
      });

    if (payloadSN) {
      await dataIntermediaryProvider.updateSilentModification(
        usersPermitted,
        payloadSN
      );
    }

    setIsLoading(false);
    closePermissonSelectModal();
    setShowInfo(false);
  };

  useEffect(() => {
    dataIntermediaryProvider.getNoTotalList("roles").then(res => {
      for (const roleSlug in res.data) {
        res.data[roleSlug].name = capitalizeWords(res.data[roleSlug].name);
      }
      const sorted = res.data.sort((a, b) => a.name.localeCompare(b.name));

      setRoleChoices(sorted);
    });

    dataIntermediaryProvider.getNoTotalList("groups").then(res => {
      const sorted = res.data.sort((a, b) => a.name.localeCompare(b.name));
      setGroupChoices(sorted);
    });
  }, []);

  const RoleGroupSelectToolbar = () => {
    return (
      <Toolbar>
        <Button
          type={"submit"}
          fullWidth
          variant={"contained"}
          size={"large"}
          label={t("resources.roles_permissions.next")}
          disabled={disableNextBtn}
          className={styles.actionBtn}
        />
      </Toolbar>
    );
  };

  const setFilteredGroupUsers = (role, group) => {
    if (group.length < 1) {
      dataIntermediaryProvider.getNoTotalList("users", { role }).then(data => {
        return setPickedGroupUsers(Object.values(data)[0]);
      });
    }

    if (!role) {
      const currentGroupName = group[0].name;
      dataIntermediaryProvider
        .getNoTotalList("groups", { name: currentGroupName })
        .then(data => {
          setPickedGroupUsers(data.data[0].users);
        });
    }

    const filteredUsers = group[0]?.users.filter(u => {
      return u.role_slug === role;
    });
    setPickedGroupUsers(filteredUsers);
  };

  const onDropdownChange = (e, optionsArr, answerPart) => {
    const idx = e.target.value;
    const temporaryAnswers = { ...answers };
    temporaryAnswers[answerPart] = optionsArr.filter(r => {
      return r.id === idx;
    });
    setAnswers(temporaryAnswers);
    setFilteredGroupUsers(
      temporaryAnswers?.role[0]?.slug,
      temporaryAnswers?.group,
      data
    );
    setDisableNextBtn(false);
  };

  /*
   *
   *
   * SECOND MODAL LOGIC
   *
   *
   * */
  const [deactivateUsers, setDeactivateUsers] = useState(false);
  const usersInit = () => {
    return (
      pickedGroupUsers?.map(us => ({
        id: us.id,
        name: us.uid,
        checked: false,
        role_slug: us.role_slug,
        last_name: us.last_name,
        label: `${us.first_name} ${us.last_name}`,
      })) || []
    );
  };

  const permissionsInit = () => {
    return (
      permissionsList?.map((p, i) => ({
        id: i,
        name: p,
        checked: false,
        label: t(`resources.roles_permissions.${p}`),
      })) || []
    );
  };

  const setPermittedUsers = usersList => {
    const list = [];
    usersList.forEach(u => {
      if (u.checked) {
        list.push(u.id);
      }
    });
    setUsersPermitted(list);
  };

  const handleGroupPermissions = permList => {
    const updatedList = {};
    permList.forEach(perm => {
      updatedList[perm.name] = perm.checked;
    });
    setGroupPermissions(updatedList);
  };

  const handleActivateUsersChange = e => {
    setDeactivateUsers(e);
  };

  // Handling Silent Notifications
  const [payloadSN, setPayloadSN] = useState(null);
  const [isSnError, setIsSnError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const setSNPayload = (payload, snError) => {
    setIsSnError(snError);
    setPayloadSN(payload);
  };

  const closeInfoModal = () => {
    setShowInfo(false);
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <EditButton
        className={styles.bulkBtn}
        label={t("resources.roles_permissions.bulkchange")}
        onClick={showModal}
      />
      <Modal
        fullWidth
        isOpen={isOpen}
        onClose={closeModal}
        title={t("resources.roles_permissions.select_roles_groups")}
        maxWidth={"md"}
      >
        <SimpleForm
          id={"role_group_modal"}
          onSubmit={showNextStep}
          toolbar={<RoleGroupSelectToolbar />}
        >
          <p className={styles.description}>
            {t("resources.roles_permissions.hint")}
          </p>
          <SelectInput
            source={"user_roles"}
            label={t("resources.roles_permissions.user_role")}
            choices={roleChoices}
            optionText="name"
            className={styles.dropdown}
            onChange={e => {
              onDropdownChange(e, roleChoices, "role");
            }}
          />
          <SelectInput
            source={"user_groups"}
            choices={groupChoices}
            label={t("resources.roles_permissions.user_group")}
            optionText="name"
            className={styles.dropdown}
            onChange={e => {
              onDropdownChange(e, groupChoices, "group");
            }}
          />
        </SimpleForm>
      </Modal>
      <Modal
        fullWidth
        isOpen={isOpenPermissonSelectModal}
        onClose={closePermissonSelectModal}
        title={t("resources.roles_permissions.set_permissions")}
        maxWidth={"xl"}
        isLoading={isLoading}
      >
        <SimpleForm
          id={"role_group_modal"}
          onSubmit={submitNewPermissions}
          toolbar={null}
        >
          <div className={styles.permissionsWrapper}>
            <div
              className={`${styles.permissionItem} ${styles.leftPermissionItems}`}
            >
              <h2 className={styles.modalTitles}>
                {t("resources.roles_permissions.permissions")}
              </h2>
              <div className={styles.scrollWrapper}>
                <SwitchGroup
                  bulkLabel={t("resources.roles_permissions.toggle_all")}
                  initialSwitchState={permissionsInit()}
                  onValueChange={handleGroupPermissions}
                  disableAll={deactivateUsers}
                />
              </div>
            </div>
            <div
              className={`${styles.permissionItem} ${styles.leftPermissionItems}`}
            >
              <h2 className={styles.modalTitles}>
                {t("resources.users.name")}
              </h2>
              <div className={styles.scrollWrapper}>
                <SwitchGroup
                  bulkLabel={t("resources.roles_permissions.toggle_all")}
                  initialSwitchState={usersInit()}
                  onValueChange={setPermittedUsers}
                  showGroups={true}
                />
              </div>
            </div>
            <div
              className={`${styles.permissionItem} ${styles.rightPermissionItems}`}
            >
              <h2 className={styles.modalTitles}>
                {t("resources.roles_permissions.activate_picked_users")}
              </h2>
              <BooleanInput
                className={styles.activateSwitch}
                label={t("resources.roles_permissions.activate_users")}
                checked={deactivateUsers}
                defaultChecked={false}
                source="activateUsersSwitch"
                color={"primary"}
                name={"activateUsersSwitch"}
                onChange={handleActivateUsersChange}
              />
              <h2 className={styles.modalTitles}>
                {t("synapseadmin.users.notifications_groups")}
              </h2>
              <SilentNotifications lifter={setSNPayload} />
            </div>
          </div>
          <Button
            type={"submit"}
            fullWidth
            variant={"contained"}
            size={"large"}
            style={{ marginTop: "2rem" }}
            label={t("resources.roles_permissions.apply")}
            disabled={usersPermitted.length === 0 || isSnError}
            className={styles.actionBtn}
          />
        </SimpleForm>
      </Modal>
      <Modal
        fullWidth
        isOpen={showInfo}
        onClose={() => {
          setShowInfo(false);
        }}
        title={"Info "}
        maxWidth={"md"}
        isLoading={false}
      >
        <div className={styles.permissionInfoWrapper}>
          <InfoCard
            text={t("synapseadmin.users.permissions_info")}
            width={"80%"}
          />
          <Button
            variant={"contained"}
            size={"large"}
            style={{ marginTop: "2rem" }}
            label={"Ok"}
            className={styles.actionBtn}
            onClick={closeInfoModal}
          />
        </div>
      </Modal>
    </TopToolbar>
  );
};

UserListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

const PermissionEditToolbar = props => {
  const notify = useNotify();
  const id = props.record.id;
  const t = useTranslate();

  const updatePermissions = async (permissionsList, id, notify, lifter) => {
    const userData = await dataIntermediaryProvider.getOne("users", { id: id });
    const isAdmin = userData.data.data.admin_staff;
    const isDeactivated = userData.data.data.mebis_deactivated;

    // Workaround to send missing permissions to the BE (as they are not shown in the UI)
    permissionsList.attachment_send = false;

    const payload = {
      deactivated: isDeactivated,
      admin: isAdmin,
      permissions: permissionsList,
    };
    lifter(true);

    dataIntermediaryProvider
      .updateUser(payload, [id])
      .then(() => {
        lifter(false);
      })
      .catch(() => {
        lifter(false);
      });
  };
  const setUpdateFunction = text => {
    return updatePermissions(props.payload, id, notify, props.lifter, text);
  };

  return (
    <Toolbar {...props}>
      <SaveButton
        onSave={() => setUpdateFunction(t("synapseadmin.users.success_msg"))}
        submitOnEnter={true}
      />
    </Toolbar>
  );
};
const PermissionEditTitle = ({ record }) => {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.users.name", {
        smart_count: 1,
      })}{" "}
      {record ? `"${record.name}"` : ""}
    </span>
  );
};
export const PermissionsEdit = props => {
  const { id } = props;
  const styles = useStyles();
  const t = useTranslate();
  const [singleUserPermissions, setSingleUserPermissions] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [perm, setPerm] = useState(null);

  const permissionsInit = permissions => {
    return (
      permissionsList?.map((p, i) => ({
        id: i,
        name: p,
        checked: permissions[p],
        label: t(`resources.roles_permissions.${p}`),
      })) || []
    );
  };

  const handleGroupPermissions = permList => {
    const updatedList = {};
    permList.forEach(perm => {
      updatedList[perm.name] = perm.checked;
    });
    setSingleUserPermissions(updatedList);
  };

  useEffect(() => {
    async function getPerms() {
      await dataIntermediaryProvider.getOne("users", { id: id }).then(res => {
        setPerm(res.data.data.permission);
      });
    }

    getPerms();
  }, [id]);

  const permissions = perm && permissionsInit(perm);
  const handleLoadingStatus = val => {
    setIsLoading(val);
  };

  return (
    <Edit {...props} mutationMode="pessimistic" title={<PermissionEditTitle />}>
      <TabbedForm
        toolbar={
          <PermissionEditToolbar
            payload={singleUserPermissions}
            lifter={handleLoadingStatus}
          />
        }
      >
        <FormTab
          label={t("resources.users.name", { smart_count: 1 })}
          icon={<PersonPinIcon />}
        >
          {isLoading && (
            <Loading
              loadingPrimary=""
              loadingSecondary=""
              className={styles.center}
            />
          )}
          <div className={isLoading ? styles.blurred : styles.visible}>
            <div className={styles.column}>
              <TextInput
                source="username"
                disabled
                label={t("resources.users.fields.uid")}
              />
              <TextInput source="name" disabled format={capitalizeWords} />
              <TextInput
                source="role_slug"
                label={t("resources.users.fields.role")}
                disabled
                format={capitalizeWords}
              />
            </div>
            <div className={styles.permissionsWrapper}>
              <div>
                <h2 className={styles.modalTitles}>
                  {t("resources.roles_permissions.permissions")}
                </h2>
                {permissions && (
                  <SwitchGroup
                    {...props}
                    bulkLabel={t("resources.roles_permissions.toggle_all")}
                    initialSwitchState={permissions}
                    break={true}
                    onValueChange={handleGroupPermissions}
                  />
                )}
              </div>
            </div>
          </div>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

const UserFilter = props => {
  const t = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput
        source="name"
        alwaysOn
        placeholder={t("resources.users.search_placeholder")}
      />
    </Filter>
  );
};
export const UserRolesPermissions = props => {
  const t = useTranslate();
  const classes = useStyles();
  const refresh = useRefresh();
  const handlePermissionUpdate = () => {
    refresh();
  };

  useEffect(() => {
    window.addEventListener("permissionsUpdate", handlePermissionUpdate);
    return () => {
      window.removeEventListener("permissionsUpdate", handlePermissionUpdate);
    };
  }, []);

  return (
    <List
      {...props}
      sort={{ field: "name", order: "ASC" }}
      pagination={<UserPagination />}
      filters={<UserFilter className={classes.searchField} />}
      actions={
        <UserListActions
          {...props}
          maxResults={10000}
          className={classes.bulkBtnWrapper}
        />
      }
      bulkActionButtons={false}
    >
      <Datagrid {...props} size={"small"} rowClick="edit">
        <CustomTextField
          property="mebis_deactivated"
          label={t("resources.users.fields.username")}
          source="username"
          sortBy="name"
          cellClassName={classes.fixElements}
          headerClassName={classes.fixElements}
        />
        <CustomTextField
          property="mebis_deactivated"
          label={t("resources.users.fields.first_name")}
          source="first_name"
          sortBy="displayname"
          className={classes.capitalized}
          cellClassName={classes.fixElementsSecond}
          headerClassName={classes.fixElementsSecond}
        />
        <CustomTextField
          property="mebis_deactivated"
          label={t("resources.users.fields.last_name")}
          source="last_name"
          sortBy="last_name"
          className={classes.capitalized}
          headerClassName={classes.fixElementsThird}
          cellClassName={classes.fixElementsThird}
        />
        <CustomTextField
          property="mebis_deactivated"
          label={t("resources.users.fields.role")}
          source="role_slug"
          className={classes.capitalized}
          headerClassName={classes.fixElementsFourth}
          cellClassName={classes.fixElementsFourth}
          sortBy="role"
        />
        {permissionsList.map((permission, i) => {
          return (
            <ColorBooleanField
              key={permission + i}
              label={t(`resources.roles_permissions.${permission}`)}
              source={t(`resources.roles_permissions.${permission}`)}
              property={permission}
              sortBy={permission}
            />
          );
        })}
      </Datagrid>
    </List>
  );
};
