import Modal from "../Modal";
import ImageCropper from "../ImageCropper";
import { useForm } from "react-final-form";
import {useState, useCallback, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Avatar} from "@material-ui/core";
import {Loading, useNotify, useQueryWithStore} from "react-admin";
import * as React from "react";
import intermediaryDataProvider from "../../../synapse/intermediaryDataProvider";
import {useTranslate} from "ra-core";

const useStyles = makeStyles(theme => ({
  avatar: () => ({
    padding: "15px 0",
    position: "relative",
    width: "100%",
    "& .avatar-form": {
      position: "relative",
    },
    "& .avatar-form label": {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.sectionHeaderFontSize,
      fontWeight: theme.typography.fontWeightMedium,
      display: "flex",
      paddingBottom: 32
    },
    "& .MuiAvatar-root": {
      height: 200,
      width: 200,
      [theme.breakpoints.up("sm")]: {
        width: 150,
        height: 150,
      },
    },
    "& .avatar-form-image": {
      position: "relative",
      height: "100%",
      width: "100%",
      zIndex: 2,
    },
    "& .avatar-form-input": {
      display: "none",
    },
  }),
  picture: () => ({
    cursor: "pointer"
  }),
  center: () => ({
    width: "fit-content",
    height: "fit-content",
    position: "absolute",
    zIndex: "99",
    top: "50%",
    left: "50%",
  }),
  visible: () => ({
    width: "100%",
  }),
  blurred: () => ({
    width: "100%",
    filter: "blur(10px)",
  }),
  description: () => ({
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    maxWidth:'16rem',
  }),
}));

const AvatarInput = ({ record, source, label }) => {
  const form = useForm();
  const [logo, setLogo] = useState(record[source]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const classes = useStyles();
  const notify = useNotify()
  const translate = useTranslate()
  const { data, loaded } = useQueryWithStore({
    type: 'getInitialSchoolLogo',
  });

  useEffect(()=>{
    if(!loaded) return
    setLogo(data.avatar)
  },[loaded,data])

  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const setImageCrop = useCallback(
    async val => {
      setLogo(val.src);
      form.change(source, val);
      setIsOpen(false);
      // To Update Logo we need to use FormData with {key: 'image', value: blob}
      const formData = new FormData();
      const croppedImage = await fetch(val.src);
      const blobOfCroppedImage = await croppedImage.blob();
      const croppedImageObject = new File([blobOfCroppedImage], val.rawFile.name, { type: val.rawFile.type });
      formData.append("image", croppedImageObject);
      setIsLoading(true)

      // Sending Post Request and no
      intermediaryDataProvider.updateSchoolLogo(formData).then(() => {
        setIsLoading(false)
      }).catch(() => {
        setIsLoading(false)
      });
    },
    [form, source, notify, translate]
  );

  return (
    <div className={classes.avatar}>
      {isLoading && (
          <Loading
              loadingPrimary=""
              loadingSecondary=""
              className={classes.center}
          />
      ) }
      <div className={`avatar-form ${isLoading && classes.blurred }`} >
        { label ? <label> {label} </label> : '' }

        <div className="avatar-form-image">
          <Avatar src={logo} onClick={openDialog} className={classes.picture}/>
        </div>
        <p className={classes.description}>
          {translate("resources.school_settings.avatar.description")}
        </p>
      </div>
      <Modal fullWidth isOpen={isOpen} onClose={handleClose}>
        <ImageCropper setImageCrop={setImageCrop}/>
      </Modal>
    </div>
  );
};

export default AvatarInput;
