import {createMuiTheme, defaultTheme} from "react-admin";

export const colors = {
    primary: '#264D7F',
    secondary: '#1C1D2F',
    success: '#009245',
    warning: '#E7C963',
    error: '#FF0000',
    white: '#FFFFFF',
    black: '#00091E',
    orange: '#EF7430'
}

/**
 * @description some MUI config parameters require rgb as color variable
 * @param hex
 * @returns {number[]}
 */
export const hexToRgb = hex => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1).match(/.{2}/g)
    .map(x => parseInt(x, 16))

/**
 * @type {Theme}
 */
export const CustomTheme = createMuiTheme({
    ...defaultTheme,

    /*
     ********************************
     ************ COLORS ************
     ********************************
    */
    palette: {
        primary: {
            main: colors.primary,
            light: colors.primary,
            dark: colors.primary,
            contrastText: colors.white
        },
        secondary: {
            main: colors.secondary,
            light: colors.secondary,
            dark: colors.secondary,
            contrastText: colors.white
        },
        error: {
            main: colors.error,
            light: colors.error,
            dark: colors.error,
            contrastText: colors.white
        },
        warning: {
            main: colors.warning,
            light: colors.warning,
            dark: colors.warning,
            contrastText: colors.white
        },
        info: {
            main: colors.primary,
            light: colors.primary,
            dark: colors.primary,
            contrastText: colors.white
        },
        success: {
            main: colors.success,
            light: colors.success,
            dark: colors.success,
            contrastText: colors.white
        }
    },

    /*
     ********************************
     ********** TYPOGRAPHY **********
     ********************************
    */
    typography: {
        fontFamily: ['"Atkinson Hyperlegible"', '"Lexend"'].join(','),
        fontSize: 16,
        sectionHeaderFontSize: "1.7rem",
        h1: {fontFamily: '"Lexend"'},
        h2: {fontFamily: '"Lexend"'},
        h3: {fontFamily: '"Lexend"'},
        h4: {fontFamily: '"Lexend"'},
        h5: {fontFamily: '"Lexend"'},
        h6: {fontFamily: '"Lexend"'}
    }
})
